const fields = {
  inputs: {
    name: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "name",
        placeholder: "Применяемый промокод",
        label: "Применяемый промокод",
        type: "text",
        always_type: "text",
      },
    },
    discount: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "discount",
        placeholder: "Размер скидки или артикул товара (только цифры)",
        label: "Размер скидки или артикул товара (только цифры)",
        type: "text",
        always_type: "text",
        maska: {
          mask: "HHHHHHHH",
          tokens: { H: { pattern: /[0-9]/ } },
        },
      },
    },
    type: {
      value: "",
      errorBag: [],
      isDirty: false,
      noValidate: false,
      attrs: {
        id: "type",
        placeholder: "Тип акции - продукт/число/процент",
        label: "Тип акции - продукт/число/процент",
        type: "text",
        always_type: "text",
        hidden: true,
      },
    },
    price: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "price",
        placeholder: "Минимальная цена для применения акции",
        label: "Минимальная цена",
      },
      errorBag: [],
      validate: {},
    },
    time_start: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "time_start",
        placeholder: "Время начала акции в часах",
        label: "Время начала акции в часах",
      },
      errorBag: [],
      validate: {},
    },
    time_end: {
      value: "",
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "time_end",
        placeholder: "Время окончания акции в часах",
        label: "Время окончания акции в часах",
      },
      errorBag: [],
      validate: {},
    },
    products: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "products",
        placeholder: "Обязательные продукты, выбрать из списка ниже",
        label: "Обязательный(ные) продукт(ы)",
      },
      errorBag: [],
      validate: {},
    },
  },
  radios: {
    integer: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "integer",
        label: "число",
      },
      errorBag: [],
      validate: {},
    },
    percent: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "percent",
        label: "процент",
      },
      errorBag: [],
      validate: {},
    },
    product: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "product",
        label: "продукт",
      },
      errorBag: [],
      validate: {},
    },
  },
  checkboxes: {
    checkbox_price: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "checkbox_price",
        label: "Минимальная цена",
      },
      errorBag: [],
      validate: {},
    },
    checkbox_time: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "checkbox_time",
        label: "Время",
      },
      errorBag: [],
      validate: {},
    },
    checkbox_products: {
      value: [],
      isDirty: false,
      noValidate: true,
      attrs: {
        id: "checkbox_products",
        label: "Обязательный продукт",
      },
      errorBag: [],
      validate: {},
    },
  },
  search: {
    value: "",
    errorBag: [],
    isDirty: false,
    noValidate: false,
    attrs: {
      id: "search",
      placeholder: "Поиск по продуктам",
      label: "Поиск по продуктам",
      type: "text",
      always_type: "text",
    },
  },
};

export { fields };
