<template>
  <section class="promotions">
    <div class="promotions__controls">
      <button
        class="promotions__delete promotions__control"
        @click="promotionController('deletePromotion')"
      >
        Удалить
      </button>
      <button
        class="promotions__append promotions__control"
        @click="promotionController('appendPromotion')"
      >
        Добавить
      </button>
      <button
        class="promotions__append promotions__control"
        @click="promotionController('changePromotion')"
      >
        Редактировать
      </button>
    </div>
    <div class="promotions__list">
      <v-radio
        class="promotions__item"
        :value="promo.attrs.label"
        :attrs="promo.attrs"
        v-model="active_promo"
        v-for="(promo, idx) in ADMIN_PROMOTIONS"
        :key="idx"
      >
        <template v-slot:append>
          <img
            :src="promo.image"
            alt=""
            class="promotions__item-image"
            :ref="promo.value"
          />
        </template>
      </v-radio>
    </div>
    <v-dialog
      class="promotions__dialog"
      :data="promotion_dialog"
      @adminSave="promotionController('setPromotion')"
      @adminDelete="promotionController('deletePromotion')"
      @closeDialog="closeDialog"
    >
      <form class="promotions__dialog-form">
        <v-input
          v-for="(field, idx) in promotion_form.fields.inputs"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="promotions__dialog-input"
          :ref="field.attrs.id"
        ></v-input>
        <div class="promotions__dialog-types">
          <v-radio
            class="promotions__dialog-radio"
            :value="field.attrs.label"
            :attrs="field.attrs"
            v-model="promotion_form.fields.inputs.type.value"
            v-for="(field, idx) in promotion_form.fields.radios"
            :key="idx"
          >
          </v-radio>
        </div>
        <v-input
          :attrs="promotion_form.fields.search.attrs"
          :errorBag="promotion_form.fields.search.errorBag"
          v-model="promotion_form.fields.search.value"
          class="promotions__dialog-input"
          :ref="promotion_form.fields.search.attrs.id"
          v-show="
            promotion_form.fields.checkboxes.checkbox_products.value.length
          "
        ></v-input>
        <div
          class="promotions__dialog-products"
          v-show="
            promotion_form.fields.checkboxes.checkbox_products.value.length
          "
        >
          <div
            class="promotions__dialog-product"
            v-for="(product, idx) in filtredAllProducts"
            :class="{
              include: promotion_form.fields.inputs.products.value.filter(
                (item) => item === product?.name
              ).length,
            }"
            :key="idx"
            @click="promotionController('selectRequiredProduct', product?.name)"
          >
            {{ product.name }}
          </div>
        </div>
        <v-checkbox
          class="promotions__dialog-checkbox"
          :value="checkbox.attrs.id"
          :attrs="checkbox.attrs"
          v-model="checkbox.value"
          :isDirty="checkbox.isDirty"
          :errorBag="checkbox.errorBag"
          v-for="(checkbox, idx) in promotion_form.fields.checkboxes"
          :key="idx"
        ></v-checkbox>
      </form>
    </v-dialog>
    <v-dialog
      class="promotions__dialog"
      :data="promotion_dialog_append"
      @adminSave="promotionController('appendPromotion')"
      @closeDialog="closeDialog"
    >
      <form class="promotions__dialog-form">
        <v-input
          v-for="(field, idx) in promotion_form.fields.inputs"
          :key="idx"
          :attrs="field.attrs"
          :errorBag="field.errorBag"
          v-model="field.value"
          class="promotions__dialog-input"
          :ref="field.attrs.id"
        ></v-input>
        <div class="promotions__dialog-types">
          <v-radio
            class="promotions__dialog-radio"
            :value="field.attrs.label"
            :attrs="field.attrs"
            v-model="promotion_form.fields.inputs.type.value"
            v-for="(field, idx) in promotion_form.fields.radios"
            :key="idx"
          >
          </v-radio>
        </div>
        <v-input
          :attrs="promotion_form.fields.search.attrs"
          :errorBag="promotion_form.fields.search.errorBag"
          v-model="promotion_form.fields.search.value"
          class="promotions__dialog-input"
          :ref="promotion_form.fields.search.attrs.id"
          v-show="
            promotion_form.fields.checkboxes.checkbox_products.value.length
          "
        ></v-input>
        <div
          class="promotions__dialog-products"
          v-show="
            promotion_form.fields.checkboxes.checkbox_products.value.length
          "
        >
          <div
            class="promotions__dialog-product"
            v-for="(product, idx) in filtredAllProducts"
            :class="{
              include: promotion_form.fields.inputs.products.value.filter(
                (item) => item === product?.name
              ).length,
            }"
            :key="idx"
            @click="promotionController('selectRequiredProduct', product?.name)"
          >
            {{ product.name }}
          </div>
        </div>
        <v-checkbox
          class="promotions__dialog-checkbox"
          :value="checkbox.attrs.id"
          :attrs="checkbox.attrs"
          v-model="checkbox.value"
          :isDirty="checkbox.isDirty"
          :errorBag="checkbox.errorBag"
          v-for="(checkbox, idx) in promotion_form.fields.checkboxes"
          :key="idx"
        ></v-checkbox>
      </form>
    </v-dialog>
  </section>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { fields } from "../../helpers/promotions_dialog";
export default {
  name: "PromotionsViews",
  data() {
    return {
      promotion_form: {
        fields,
      },
      clone_promotion_form: {},
      refresh_form: {},
      promotion_dialog: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Управление акцией",
      },
      promotion_dialog_append: {
        options: {
          visible: false,
          controll: "",
          require_auth: true,
        },
        title: "Добавить акцию",
      },
      active_promo: "",
      checkboxes_flag: false,
    };
  },
  methods: {
    ...mapActions([
      "GET_ADMIN_PROMOTIONS",
      "APPEND_PROMOTION",
      "CHANGE_PROMOTION",
      "DELETE_PROMOTION",
      "GLOBAL_NOTIFICATIONS",
      "GET_ALL_PRODUCTS",
    ]),
    async promotionController(action, ...params) {
      let settings = params?.[0];
      console.log(settings);
      console.log(action);
      if (action == "appendPromotion") {
        try {
          if (!this.promotion_dialog_append.options.visible) this.refreshForm();
          if (this.promotion_dialog_append.options.visible) {
            let valid = await this.promotionValidation();
            if (valid)
              this.APPEND_PROMOTION(this.promotion_form.fields.inputs).then(
                (res) => {
                  this.GLOBAL_NOTIFICATIONS(res);
                  if (res?.status == 200) {
                    this.refreshForm("promotion_dialog_append");
                  }
                  setTimeout(async () => {
                    await this.GET_ADMIN_PROMOTIONS();
                  }, 500);
                }
              );
          } else this.openDialog(this.promotion_dialog_append);
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "setPromotion") {
        try {
          let valid = await this.promotionValidation(action);
          if (valid) {
            let newForm = this.promotion_form.fields.inputs;
            let oldForm = this.clone_promotion_form.fields.inputs;
            await this.CHANGE_PROMOTION({ newForm, oldForm }).then((res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              if (res?.status == 200) {
                this.refreshForm("promotion_dialog");
              }
              setTimeout(async () => {
                await this.GET_ADMIN_PROMOTIONS();
              }, 500);
            });
          }
        } catch (error) {
          console.log(error);
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "deletePromotion") {
        try {
          await this.DELETE_PROMOTION({ promotion: this.activePromo }).then(
            (res) => {
              this.GLOBAL_NOTIFICATIONS(res);
              setTimeout(async () => {
                await this.GET_ADMIN_PROMOTIONS();
                this.active_promo = this.ADMIN_PROMOTIONS?.[0]?.value;
              }, 500);
            }
          );
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "changePromotion") {
        try {
          if (this.activePromo == "") {
            throw {
              title: "Акция не выбрана",
              text: "Выберите акцию",
              status: "error",
              clean: false,
            };
          }
          let form = this.promotion_form.fields;
          let promo = Array.from(this.ADMIN_PROMOTIONS).filter(
            (item) => item.value.toLowerCase() == this.activePromo.toLowerCase()
          )?.[0];
          this.openDialog(this.promotion_dialog);
          form.inputs.name.value = promo.value;
          form.inputs.discount.value = promo.discount + "";
          form.inputs.type.value = promo.type;
          console.log(promo);
          if (promo.conditions?.price) {
            form.checkboxes.checkbox_price.value = ["checkbox_price"];
            form.inputs.price.value = promo.conditions.price + "";
          } else {
            form.checkboxes.checkbox_price.value = [];
          }
          if (promo.conditions?.time) {
            form.checkboxes.checkbox_time.value = ["checkbox_time"];
            form.inputs.time_start.value = promo.conditions.time.start + "";
            form.inputs.time_end.value = promo.conditions.time.end + "";
          } else {
            form.checkboxes.checkbox_time.value = [];
          }
          if (promo.conditions?.products) {
            form.checkboxes.checkbox_products.value = ["checkbox_products"];
            form.inputs.products.value = promo.conditions.products;
          } else {
            form.checkboxes.checkbox_products.value = [];
          }
          // form.checkboxes.value("");
          this.clone_promotion_form = JSON.parse(
            JSON.stringify(this.promotion_form)
          );
        } catch (error) {
          if (error?.title) {
            this.GLOBAL_NOTIFICATIONS(error);
          }
        }
      }
      if (action == "selectRequiredProduct") {
        let { products } = this.promotion_form.fields.inputs;
        if (!products.value.filter((item) => item === settings).length)
          products.value.unshift(settings);
        else
          products.value = products.value.filter((item) => item !== settings);
      }
    },
    async promotionValidation(action) {
      try {
        const form = this.promotion_form.fields;
        if (action !== "setPromotion") {
          if (
            Array.from(this.ADMIN_PROMOTIONS).filter(
              (item) =>
                item.value.toLowerCase() == form.inputs.name.value.toLowerCase()
            ).length
          ) {
            throw {
              title: "Такой промокод уже существует",
              text: "укажите другое название",
              status: "error",
              clean: false,
            };
          }
        }
        if (form.inputs.name.value == "") {
          throw {
            title: "Не указано название применяемого промокода",
            text: "Укажите название промокода, по нему пользователь будет применять его",
            status: "error",
            clean: false,
          };
        }
        if (
          !Array.from(this.ALL_PRODUCTS).filter(
            (item) => item.id === +form.inputs.discount.value
          ).length &&
          form.inputs.type.value == "продукт"
        ) {
          throw {
            title: "ID указанного продукта не найден в базе данных",
            text: "Проверьте продукты на соответствие идентификаторов в Frontpad и Админ панели",
            status: "error",
            clean: false,
          };
        }
        if (this.requireCheckboxes) {
          throw {
            title: "Не выбрано ни одно условие для акции",
            text: "Отметьте один или несколько чекбоксов и укажите условия для применения акции",
            status: "error",
            clean: false,
          };
        }
        if (form.inputs.name.value == "") {
          throw {
            title: "Не заполнено имя акции",
            text: "По нему пользователи будут применять акцию",
            status: "error",
            clean: false,
          };
        }
        if (form.inputs.type.value == "") {
          throw {
            title: "Не выбран тип акции",
            text: "Выберите тип акции из 3х вариантов ниже",
            status: "error",
            clean: false,
          };
        }
        if (form.inputs.discount.value == "") {
          throw {
            title: "Не выбран размер скидки",
            text: "Укажите размер скидки",
            status: "error",
            clean: false,
          };
        }
        if (
          form.inputs.price.value == "" &&
          form.checkboxes.checkbox_price.value.length
        ) {
          throw {
            title: "Не выбрана минимальная сумма для применения промокода",
            text: "Укажите размер суммы",
            status: "error",
            clean: false,
          };
        }
        if (
          (form.inputs.time_start.value == "" ||
            form.inputs.time_end.value == "") &&
          form.checkboxes.checkbox_time.value.length
        ) {
          throw {
            title: "Не выбрано время действия промокода",
            text: "Укажите время начала и конца промокода в часах",
            status: "error",
            clean: false,
          };
        }
        if (
          !form.inputs.products.value.length &&
          form.checkboxes.checkbox_products.value.length
        ) {
          throw {
            title:
              "Не выбран ни один обязательный продукт для применения промокода",
            text: "Выберите хотя бы один обязательный продукт",
            status: "error",
            clean: false,
          };
        }
        return true;
      } catch (error) {
        if (error?.title) {
          this.GLOBAL_NOTIFICATIONS(error);
        }
        return false;
      }
    },
    async closeDialog(data) {
      data.options.visible = false;
    },
    openDialog(dialog) {
      dialog.options.visible = true;
    },
    refreshForm(dialog) {
      this.promotion_form = JSON.parse(JSON.stringify(this.refresh_form));
      if (dialog !== undefined) {
        this[dialog].options.visible = false;
      }
    },
  },
  computed: {
    ...mapGetters(["ADMIN_PROMOTIONS", "ALL_PRODUCTS"]),
    activePromo() {
      return this.active_promo;
    },
    requireCheckboxes() {
      let checkboxes = Object.assign({}, this.promotion_form.fields.checkboxes);
      if (
        !checkboxes.checkbox_price.value.length &&
        !checkboxes.checkbox_time.value.length &&
        !checkboxes.checkbox_products.value.length
      ) {
        return true;
      } else return false;
    },
    filtredAllProducts() {
      let filtredArr = Array.from(this.ALL_PRODUCTS).filter((item) =>
        item.name
          .toLowerCase()
          .includes(this.promotion_form.fields.search.value.toLowerCase())
      );
      if (filtredArr.length) return filtredArr;
      else return this.ALL_PRODUCTS;
    },
  },
  watch: {
    "promotion_form.fields.checkboxes.checkbox_price.value": {
      handler(newVal) {
        let val = newVal.length,
          input = this.$refs.price?.$el;
        if (!val) {
          this.promotion_form.fields.inputs.price.value = "";
          input?.classList?.add("hidden");
        } else input?.classList?.remove("hidden");
      },
    },
    "promotion_form.fields.checkboxes.checkbox_time.value": {
      handler(newVal) {
        let val = newVal.length,
          input1 = this.$refs.time_start?.$el,
          input2 = this.$refs.time_end?.$el;
        if (!val) {
          this.promotion_form.fields.inputs.time_start.value = "";
          this.promotion_form.fields.inputs.time_end.value = "";
          input1?.classList?.add("hidden");
          input2?.classList?.add("hidden");
        } else {
          input1?.classList?.remove("hidden");
          input2?.classList?.remove("hidden");
        }
      },
    },
    "promotion_form.fields.checkboxes.checkbox_products.value": {
      handler(newVal) {
        let val = newVal.length,
          input = this.$refs.products?.$el;
        if (!val) {
          this.promotion_form.fields.inputs.products.value = [];
          input?.classList?.add("hidden");
        } else input?.classList?.remove("hidden");
      },
    },
  },
  async mounted() {
    await this.GET_ADMIN_PROMOTIONS();
    await this.GET_ALL_PRODUCTS();
    this.refresh_form = JSON.parse(JSON.stringify(this.promotion_form));
    this.active_promo = Array.from(this.ADMIN_PROMOTIONS)?.[0]?.value;
    // this.promotionController("appendPromotion");
    this.promotion_form.fields.checkboxes.checkbox_price.value = [];
    this.promotion_form.fields.checkboxes.checkbox_time.value = [];
    this.promotion_form.fields.checkboxes.checkbox_products.value = [];
  },
};
</script>
<style lang="scss" scoped>
.promotions {
  /* .promotions__section */

  &__section {
  }

  /* .promotions__controls */

  &__controls {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  /* .promotions__delete */

  &__delete {
  }

  /* .promotions__control */

  &__control {
    max-width: 24%;
    width: 100%;
    padding: 14px;
    border: 1px solid #ff6800;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
  }

  /* .promotions__append */

  &__append {
  }

  /* .promotions__list */

  &__list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  /* .promotions__item */

  &__item {
    width: 32%;
    margin-bottom: 12px;
  }

  &__dialog-form {
  }
  /* .promotions__dialog-input */

  &__dialog-input {
    margin-bottom: 12px;
    &.hidden {
      display: none;
    }
  }
  /* .promotions__dialog-types */

  &__dialog-types {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  /* .promotions__dialog-radio */

  &__dialog-radio {
    width: 32%;
  }
  /* .promotions__dialog-checkbox */

  &__dialog-checkbox {
    margin-bottom: 24px;
  }

  /* .promotions__dialog-products */

  &__dialog-products {
    display: flex;
    align-items: stretch;
    overflow-x: auto;
  }

  /* .promotions__dialog-product */

  &__dialog-product {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    justify-content: center;
    min-width: 150px;
    max-width: 150px;
    width: 100%;
    cursor: pointer;
    padding: 24px;
    transition: 0.3s ease;
    border-radius: 10px;
    margin-right: 12px;
    &:last-child {
      margin-right: 0;
    }
    &:hover {
      background-color: $unactive-accent;
    }
    &.include {
      background-color: $accent;
    }
  }
}
</style>
<style lang="scss"></style>
